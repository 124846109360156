import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { login } from 'actions/user';
import { setAccessToken } from 'actions/app';
import { LOGIN_FAILURE } from 'types/user';

import Button from 'components/Button';
import Link from 'components/Link';
import Message from 'components/Message';
import { H3, SecondaryHierarchyText } from 'components/Common/Typography';
import { BlockSpace, InlineSpace } from 'components/Common/Spacing';

import { routeToQueryRedirect, setAccessTokenHeader } from 'utils';

import styles from './Login.css';

const ENTER_KEY_CODE = 13;
export default function Login({ title }) {
  useStyles(styles);

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { error } = useSelector(state => state.user);

  const postLogin = async () => {
    const loginResponse = await dispatch(
      login({
        email,
        password,
      }),
    );

    if (loginResponse.type === LOGIN_FAILURE) {
      return;
    }
    const {
      payload: { accessToken },
    } = loginResponse;
    dispatch(setAccessToken(accessToken));
    setAccessTokenHeader(accessToken);
    routeToQueryRedirect();
  };

  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY_CODE) {
      return postLogin();
    }
    return null;
  };

  return (
    <div>
      <div className={styles.container}>
        <H3>{title}</H3>
        <BlockSpace size={200} />
        {error && <Message messageType="error">{error}</Message>}
        <form>
          <div>
            <label htmlFor="email">
              Email address:
              <input
                className={styles.input}
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                onKeyDown={onKeyDown}
              />
            </label>
          </div>
          <BlockSpace size={100} />
          <div>
            <label htmlFor="password">
              Password:
              <input
                className={styles.input}
                id="password"
                type="password"
                name="password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                onKeyDown={onKeyDown}
              />
            </label>
            <BlockSpace size={100} />
            <Link to="/forgot-password">
              <SecondaryHierarchyText>Forgot Password</SecondaryHierarchyText>
            </Link>
          </div>
          <BlockSpace size={100} />
          <div>
            <Button buttonType="primary" onClick={postLogin}>
              Log in
            </Button>
          </div>
        </form>
        <BlockSpace size={200} />
        <div>
          <SecondaryHierarchyText>
            Don&apos;t have account yet?
            <InlineSpace size={25} />
            <Link to="/register">Register</Link>
          </SecondaryHierarchyText>
          <BlockSpace size={100} />
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  title: PropTypes.string.isRequired,
};
