// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--size-static-border-width-1:1px;--size-static-12:2px;--size-static-25:4px;--size-static-50:8px;--size-static-75:12px;--size-static-100:16px;--size-static-150:24px;--size-static-200:32px;--size-static-300:48px;--size-static-400:64px;--size-static-600:96px;--size-static-800:128px;--size-static-1200:192px;--size-static-1600:256px;--size-static-2400:384px;--size-static-3200:512px;--size-static-4000:640px;--size-static-4800:768px;--size-static-6400:1024px;--size-12:2px;--size-25:4px;--size-50:8px;--size-75:12px;--size-100:16px;--size-150:24px;--size-200:32px;--size-300:48px;--size-400:64px;--size-600:96px;--size-800:128px;--size-1200:192px;--size-1600:256px;--size-2400:384px;--size-3200:512px;--size-4000:640px;--size-4800:768px;--size-6400:1024px}@media (max-width:768px){:root{--size-12:2px;--size-25:4px;--size-50:10px;--size-75:14px;--size-100:18px;--size-150:28px;--size-200:36px;--size-300:54px;--size-400:72px;--size-600:108px;--size-800:144px;--size-1200:216px;--size-1600:288px;--size-2400:432px;--size-3200:576px;--size-4000:720px}}._2IZbL{margin:0 auto;padding:0 0 32px;padding:0 0 var(--size-static-200);max-width:384px;max-width:var(--size-static-2400)}.HfRjv{display:block;width:100%}", ""]);
// Exports
exports.locals = {
	"container": "_2IZbL",
	"input": "HfRjv"
};
module.exports = exports;
