import React from 'react';
import Layout from 'layouts/Main';
import Login from './Login';

const title = 'Log In';

function action(context) {
  return {
    routeName: 'login',
    chunks: ['login'],
    title,
    component: (
      <Layout>
        <Login title={title} />
      </Layout>
    ),
    redirectOnAuthenticated: context.route.redirectOnAuthenticated,
  };
}

export default action;
